export class User {
  public id: string;
  public fname: string;
  public lname: string;
  public email: string;
  public institution: string;
  public nidNumber: string;
  public phone: string;
  public title: string;
  public pathToSignatureFile: string;
  public fileName: string;
  public password: string;
  public currentPassword: string;
  public confirmPassword: string;
}
