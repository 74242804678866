import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { empty } from 'src/app/utils/functions/functions';

@Injectable({
  providedIn: 'root',
})
export class TransferDataService {
  private authUser = new BehaviorSubject({});
  private documentSigners = new BehaviorSubject({});
  private authUserType = new BehaviorSubject({});
  constructor() { }

  setAuthUser(user: any) {
    this.authUser.next(user);
    if (!empty(user)) {
      this.setAuthUserType({ type: user.userType });
    }
  }
  getAuthUser() {
    return this.authUser.asObservable();
  }

  setActiveDocumentSigners(data: object) {
    return this.documentSigners.next(data);
  }

  getActiveDocumentSigners() {
    return this.documentSigners.asObservable();
  }
  setAuthUserType(type: object) {
    this.authUserType.next(type);
  }
  getAuthUserType() {
    return this.authUserType.asObservable();
  }
}
