import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './alert/alert.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SuccessComponent } from './success/success.component';
import { BackbuttonComponent } from './backbutton/backbutton.component';
import { DangerComponent } from './danger/danger.component';
import { FooterComponent } from './footer/footer.component';
import { FiscalYearSelectionComponent } from './fiscal-year-selection/fiscal-year-selection.component';

@NgModule({
  declarations: [
    DangerComponent,
    SuccessComponent,
    AlertComponent,
    NavbarComponent,
    SidebarComponent,
    SuccessComponent,
    BackbuttonComponent,
    DangerComponent,
    FooterComponent,
    FiscalYearSelectionComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule],
  exports: [
    DangerComponent,
    SuccessComponent,
    AlertComponent,
    NavbarComponent,
    SidebarComponent,
    BackbuttonComponent,
    FooterComponent,
  ],
})
export class ComponentModule {}
