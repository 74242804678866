import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-president',
  templateUrl: './president.component.html',
  styleUrls: ['./president.component.scss']
})
export class PresidentComponent implements OnInit {

  constructor() { }
  toggleSidebar(){
  
    $(()=>{
      let sidebar = $('#sidebar');
      if(sidebar.hasClass('active')){
        sidebar.removeClass('active')
        $('#triggerIcon').removeClass('fa-times');
        $('#triggerIcon').addClass('fa-bars');
      }
      else{
        sidebar.addClass('active');
        $('#triggerIcon').removeClass('fa-bars');
        $('#triggerIcon').addClass('fa-times');
      }
    })
  }
  ngOnInit() {
  }

}
