import { Component, OnInit } from '@angular/core';
import { FiscalYearService } from '../../services/fiscal-year/fiscal-year.service';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-fiscal-year-selection',
  templateUrl: './fiscal-year-selection.component.html',
  styleUrls: ['./fiscal-year-selection.component.scss'],
})

export class FiscalYearSelectionComponent implements OnInit {
  fiscal: string;
  fiscalYears: any = [];
  fiscalYearList: any = [];
  editSelectedFY: string

  constructor(private fiscalYearService: FiscalYearService, private shared: SharedService) {
  }


  ngOnInit() {
    this.shared.cast.subscribe(fiscal => this.fiscal = fiscal)
    this.fiscalYearService.getAll().subscribe((listOf: any[]) => {
    this.fiscalYearList = listOf.shift();
    this.editSelectedFY = this.fiscalYearList.id
    this.shared.editFiscalYear(this.editSelectedFY);
    });
  }

  // ngOnInit() {
  //   this.shared.cast.subscribe(fiscal => this.fiscal = fiscal)
  //   this.fiscalYearService.getAll().subscribe((fiscalYears) => this.fiscalYears = fiscalYears);
  //   this.fiscalYearService.getAll().subscribe((listOf: any[]) => {
  //   this.fiscalYearList = listOf.shift();
  //   this.editSelectedFY = this.fiscalYearList.id
  //   this.shared.editFiscalYear(this.editSelectedFY);
  //   });
  // }

  editFiscalYear() {
    this.shared.editFiscalYear(this.editSelectedFY);
    console.log(this.editSelectedFY + " " + this.fiscal)
  }

  selectchange(args) {
    this.editSelectedFY = args.target.value;
    this.shared.editFiscalYear(this.editSelectedFY);  
  }


}
