import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Subject } from 'rxjs';
import { AuthData } from 'src/app/utils/models/authData';
import { environment } from 'src/environments/environment';
import { IUser, userTypes } from '../../utils/models/user.model';
import { TransferDataService } from '../transfer-data/transfer-data.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string;
  private isAuthenticated = false;
  private user: IUser;
  private authError = '';
  constructor(
    private router: Router,
    private http: HttpClient,
    private transferService: TransferDataService
  ) { }
  getAuthError() {
    return this.authError;
  }
  login(credentials: object) {
    return this.http.post(environment.API_URL + '/auth/signin', credentials);
  }
  initiateResetPassword(email: object) {
    return this.http.post(environment.API_URL + '/auth/initiate-reset-password', email);
  }
  resetPassword(data: object) {
    return this.http.post(environment.API_URL + '/auth/reset-password', data);
  }
  public handleRoutingUsers() {
    switch (this.user.userType) {
      case userTypes.ADMIN:
        this.router.navigate(['/admin']);
        break;
      // case userTypes.ADMIN:
      //   this.router.navigate(['/admin']);
      //   break;
      case userTypes.SYSTEM:
        this.router.navigate(['/admin']);
        break;
      case userTypes.ANONYMOUS:
        this.router.navigate(['/profile-settings']);
        break;
      case userTypes.PRESIDENT:
        this.router.navigate(['/president']);
        break;
      case userTypes.CONTRACT_UPLOADER:
        this.router.navigate(['/imihigo-uploader']);
        break;
      case userTypes.VIEWER:
        this.router.navigate(['/imihigo-viewer']);
        break;
      case userTypes.SETTER:
        this.router.navigate(['/imihigo-setter']);
        break;
      default:
        this.router.navigate(['/not-found']);
        break;
    }
  }
  getIsAuth() {
    return this.isAuthenticated;
  }
  saveAuthData(token: string) {
    localStorage.setItem('auth-token', token);
  }
  getToken() {
    return localStorage.getItem('auth-token');
  }
  removeToken() {
    return localStorage.removeItem('auth-token');
  }
  autoAuthUser() {
    const authInfo = this.getToken();
    if (!authInfo) {
      return;
    }
    this.token = authInfo;
    this.isAuthenticated = true;
    // this.getCurrentLoggedinUser();
  }
  private clearAuthData() {
    localStorage.removeItem('auth-token');
  }
  loggedIn() {
    const token = this.getToken();
    if (!!token) {
      try {
        return jwt_decode(token);
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }
  logout() {
    this.http.get(environment.API_URL + '/auth/logout');
    this.removeToken();
    this.router.navigate(['/login']);
  }
}
