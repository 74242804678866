import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TransferDataService } from 'src/app/services/transfer-data/transfer-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { empty } from 'src/app/utils/functions/functions';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  user: any;
  avatar: string = '';
  userType: any;
  constructor(
    private authService: AuthService,
    private transferService: TransferDataService,
    private userService: UserService
  ) {
    this.user = {
      title: '',
      firstName: '',
      lastName: '',
    };
  }

  toggleSidebar() {
    alert('In nav');
    // this.sidebar.triggerSidebar();
  }
  hello() {
    alert('Hello there');
  }

  ngOnInit() {
    //alert(this.getCurretUser())
    //this.getUser();
    this.getCurretUser();
  }
  onLogout() {
    this.authService.logout();
  }

  getUser() {

    this.transferService.getAuthUser().subscribe((user: any) => {
      if (empty(user)) {
        return;
      }
      this.user = user;
      this.userType = user.userType;
      this.avatar = !this.user.imageUrl
        ? `https://ui-avatars.com/api/?name=${this.user.firstName}+${this.user.lastName}&rounded=true&size=100&background=ff6d6d&color=fff`
        : this.user.imageUrl;
    });
  }

  getCurretUser() {
    this.userService.getCurrentUser().subscribe((user: any) => {
      if (empty(user)) {
        return;
      }
      this.user = user;
      this.userType = user.userType;
      this.avatar = !this.user.imageUrl
        ? `https://ui-avatars.com/api/?name=${this.user.firstName}+${this.user.lastName}&rounded=true&size=100&background=ff6d6d&color=fff`
        : this.user.imageUrl;
    })
  }
}
