
import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-imihigo-uploader',
  templateUrl: './imihigo-uploader.component.html',
  styleUrls: ['./imihigo-uploader.component.scss']
})
export class ImihigoUploaderComponent implements OnInit {

  constructor() { }
  toggleSidebar() {

    $(() => {
      let sidebar = $('#sidebar');
      if (sidebar.hasClass('active')) {
        sidebar.removeClass('active')
        $('#triggerIcon').removeClass('fa-times');
        $('#triggerIcon').addClass('fa-bars');
      }
      else {
        sidebar.addClass('active');
        $('#triggerIcon').removeClass('fa-bars');
        $('#triggerIcon').addClass('fa-times');
      }
    })
  }
  ngOnInit() {
  }

}
