import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { empty } from 'src/app/utils/functions/functions';
import { userTypes } from 'src/app/utils/models/user.model';
import { TransferDataService } from '../../transfer-data/transfer-data.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  roles = userTypes;
  constructor(
    private router: Router,
    private transferService: TransferDataService
  ) {}

  canActivate() {
    if (this.isAdmin()) {
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: { isAuthorised: false },
      });
      return false;
    }
  }

  isAdmin() {
    let isUserAdmin: boolean;
    let started = false;
    this.transferService.getAuthUserType().subscribe((res: any) => {
      if (empty(res)) {
        return;
      }
      const role = res.type;
      if (role === this.roles.ADMIN || role === this.roles.SYSTEM) {
        isUserAdmin = true;
      } else {
        isUserAdmin = false;
      }
      started = true;
    });
    if (started) {
      return isUserAdmin;
    }
  }
}
