import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PresidentComponent } from './layouts/president/president.component';
import { AdministratorComponent } from './layouts/administrator/administrator.component';
import { ImihigoViewerComponent } from './layouts/imihigo-viewer/imihigo-viewer.component';
import { ImihigoUploaderComponent } from './layouts/imihigo-uploader/imihigo-uploader.component';
import { ImihigoSetterComponent } from './layouts/imihigo-setter/imihigo-setter.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { ComponentModule } from './components/component.module';
import { SecurityComponent } from './pages/security/security.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { UploaderCreateAccountComponent } from './pages/uploader-create-account/uploader-create-account.component';
import { UpdateAccountComponent } from './pages/update-account/update-account.component';
import { MatchPasswordDirective } from './directives/match-password.directive';
import { TokenInterceptorService } from './services/interceptors/token/token-interceptor.service';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { DropzoneDirective } from './directives/dropzone.directive';
import { ViewPrintImihigoDocumentComponent } from './pages/view-print-imihigo-document/view-print-imihigo-document.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

@NgModule({
  declarations: [
    AppComponent,
    AdministratorComponent,
    PresidentComponent,
    ImihigoViewerComponent,
    ImihigoUploaderComponent,
    ImihigoSetterComponent,
    LoginComponent,
    PageNotFoundComponent,
    ForgotPasswordComponent,
    UploaderCreateAccountComponent,
    SecurityComponent,
    UpdateAccountComponent,
    UpdatePasswordComponent,
    MatchPasswordDirective,
    DropzoneDirective,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
