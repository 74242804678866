import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DIGIT_PATTERN, PASSWORD_PATTERN } from 'src/app/utils/constants/constants';
import { checkPasswords } from 'src/app/utils/validators/confirmed-validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  alertUser: boolean;
  step: number;
  alertClass: string;
  alertMessage: string;
  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService) {
    this.step = 2;
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email
        ],
      ],
    });

  }

  onForgotPassword() {
    this.authService.initiateResetPassword(this.forgotPasswordForm.value).subscribe((res: any) => {
      this.alertUser = true;
      this.alertClass = 'alert-success';
      this.alertMessage = res.message;
      this.step = 2;

    }, (error: HttpErrorResponse) => {
      this.alertUser = true;
      this.alertClass = 'alert-danger';
      this.alertMessage =
        error.status === 401 ? error.error.message : 'An error occured';

    });
    // this.validateAllFormFields(this.forgotPasswordForm);
  }



  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
