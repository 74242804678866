import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  fiscalYear = new BehaviorSubject<string>("");
  cast = this.fiscalYear.asObservable();

  message: any
  constructor(private router: Router) {

  }

  setMessage(data) {
    this.message = data
  }
  getMessage() {
    return this.message
  }

  editFiscalYear(newFiscalYear) {
    this.fiscalYear.next(newFiscalYear);
    // this.onReflesh();
  }

  onReflesh() {
    let currentUrl = this.router.url;
    let urlToBeUsed: string;
    let urlArray = ["/imihigo-uploader", "/imihigo-setter"];
    let urlToUse = currentUrl.indexOf("/imihigo-uploader") != -1 ? "/imihigo-uploader" : "/imihigo-setter";
    let isInUrlArray = currentUrl.includes(urlToUse)
    if (isInUrlArray) {
      if (urlToUse.length != currentUrl.length) {
        urlToBeUsed = urlToUse;
      } else {
        urlToBeUsed = urlToUse.concat("/imihigo/joint");
      }
    } else {
      let urlToCompare = "/president"
      if (currentUrl.length === urlToCompare.length) {
        urlToBeUsed = "president";
      } else {
        urlToBeUsed = "president/imihigo/joint";
      }
    }
    this.router.navigateByUrl(urlToBeUsed, { skipLocationChange: false }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }



  // onReflesh() {
  //   this.router.navigateByUrl(this.router.url)
  //     .then(() => {
  //       this.router.navigated = true;
  //       this.router.navigate(['imihigo-uploader']);
  //     });
  // }
}
