import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { empty } from 'src/app/utils/functions/functions';
import { userTypes } from 'src/app/utils/models/user.model';
import { TransferDataService } from '../../transfer-data/transfer-data.service';

@Injectable({
  providedIn: 'root'
})
export class ImihigoUploaderGuard implements CanActivate {
  roles = userTypes;
  constructor(private transferService: TransferDataService, private router: Router) { }
  canActivate() {
    if (this.isUploader()) {
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { isAuthorised: false } });
      return false;
    }
  }

  isUploader() {
    let isUserUploader: boolean;
    const started = true;
    this.transferService.getAuthUser().subscribe((user: any) => {
      if (empty(user)) { return; }
      const role = user.userType;
      if (role === this.roles.CONTRACT_UPLOADER) {
        isUserUploader = true;
      } else {
        isUserUploader = false;
      }
    });
    if (started) { return isUserUploader; }
  }

}
