import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ImihigoStatusService {

  constructor(private httpClient: HttpClient) { }

  getStatus() {
    return this.httpClient.get(environment.API_URL + '/dashboard/general');
  }

  getGeneralViewStatus(fiscalYear: string) {
    return this.httpClient.get(environment.API_URL + '/dashboard/general?fiscalYearId='+fiscalYear);
  }
}
