import { Component, OnInit } from '@angular/core';
import { User } from '../../user';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { ConfirmedValidator } from '../../utils/validators/confirmed-validator';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
})
export class SecurityComponent implements OnInit {
  userModal: User;

  constructor() {}

  onSubmit() {
    console.table(this.userModal);
  }

  ngOnInit() {}
}
