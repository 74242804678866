import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/user';

@Component({
  selector: 'app-uploader-create-account',
  templateUrl: './uploader-create-account.component.html',
  styleUrls: ['./uploader-create-account.component.scss'],
})
export class UploaderCreateAccountComponent implements OnInit {
  userModal: User;

  constructor() {}

  onSubmit() {
    console.table(this.userModal);
  }

  ngOnInit() {}
}
