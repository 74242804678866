import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth/auth.service';
import { ImihigoStatusService } from 'src/app/services/imihigoStatus/imihigo-status.service';
import { TransferDataService } from 'src/app/services/transfer-data/transfer-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { empty } from 'src/app/utils/functions/functions';
import { userTypes } from 'src/app/utils/models/user.model';
import { UsernameValidator } from './UsernameValidator';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  errorMessage: string;
  AlerterrorMessage: string;
  AlerterrorClass = 'danger';
  alertUser: boolean;
  alertMessage: string;
  alertClass: string;
  isloading = false;

  role = userTypes;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private transferService: TransferDataService,
    private router: Router,
    private imihigoStatusService: ImihigoStatusService
  ) {
    this.routeActivationCheck();
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3),UsernameValidator.cannotContainSpace]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
        ],
      ],
    });
  }

  ngOnInit() { }
  onLogin() {
    this.isloading = false;
    this.AlerterrorMessage = '';
    this.validateAllFormFields(this.loginForm);

    if (this.loginForm.invalid) {
      return;
    }

    this.isloading = true;
    this.authService.login(this.loginForm.value).subscribe(
      (response: any) => {
        const token = response.data.token;
        this.isloading = false;
        localStorage.setItem('auth-token', token);
        this.setUser();
      },
      (error: HttpErrorResponse) => {
        this.isloading = false;
        this.alertUser = true;
        this.alertClass = 'alert-danger';
        this.alertMessage =
          error.status === 401 ? error.error.message : 'An error occured.';
      }
    );
  }

  get f(){
    return this.loginForm.controls;
  }

  routeActivationCheck() {
    this.activatedRoute.queryParams.subscribe(params => {
      const isAuthorized = params.isAuthorised || null;
      if (isAuthorized) {
        this.alertUser = true;
        this.alertClass = 'alert-secondary';
        this.alertMessage = 'Not Authorized';
      }
      const isLoggedIn = params.isLoggedIn || null;
      if (isLoggedIn) {
        this.alertUser = true;
        this.alertClass = 'alert-secondary';
        this.alertMessage = 'Login to continue !!';
      }
    });
  }

  setUser() {
    const {user} = this.authService.loggedIn() as any;
    if (!user) {
      return false;
    }
    this.transferService.setAuthUser(user);
    this.transferService.getAuthUserType().subscribe((res: any) => {
      if (empty(res)) {
        return;
      }
      this.routeUser(res.type);
      return;
    });
  }

  routeUser(role: string) {
    this.imihigoStatusService.getStatus().subscribe(
      (res: any) => {
        if (res.pendingDocs > 0) {
          if (role === this.role.ADMIN || role === this.role.SYSTEM) {
            this.router.navigate(['/admin']);
          } else if (role === this.role.PRESIDENT) {
            this.router.navigate(['/president/imihigo-signing']);
          } else if (role === 'VIEWER') {
            this.router.navigate(['/imihigo-viewer']);
          } else if (role === this.role.SETTER) {
            this.router.navigate(['/imihigo-setter/imihigo-signing']);
          } else if (role === this.role.CONTRACT_UPLOADER) {
            this.router.navigate(['/imihigo-uploader']);
          } else {
            this.router.navigate(['/**']);
          }
        } else {
          if (role === this.role.ADMIN || role === this.role.SYSTEM) {
            this.router.navigate(['/admin']);
          } else if (role === this.role.PRESIDENT) {
            this.router.navigate(['/president']);
          } else if (role === 'VIEWER') {
            this.router.navigate(['/imihigo-viewer']);
          } else if (role === this.role.SETTER) {
            this.router.navigate(['/imihigo-setter']);
          } else if (role === this.role.CONTRACT_UPLOADER) {
            this.router.navigate(['/imihigo-uploader']);
          } else {
            this.router.navigate(['/**']);
          }
        }
      },
      (err: HttpErrorResponse) => {
        if (role === this.role.ADMIN || role === this.role.SYSTEM) {
          this.router.navigate(['/admin']);
        } else if (role === this.role.PRESIDENT) {
          this.router.navigate(['/president']);
        } else if (role === 'VIEWER') {
          this.router.navigate(['/imihigo-viewer']);
        } else if (role === this.role.SETTER) {
          this.router.navigate(['/imihigo-setter']);
        } else if (role === this.role.CONTRACT_UPLOADER) {
          this.router.navigate(['/imihigo-uploader']);
        } else {
          this.router.navigate(['/**']);
        }
      }
    );
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
