import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FiscalYearService {

  constructor(private clientHttp: HttpClient) { }

  getAll() {
    return this.clientHttp.get(environment.API_URL + '/fiscal-years');
  }
  createFiscalYear(fiscalYear: object) {
    return this.clientHttp.post(environment.API_URL + '/fiscal-years', fiscalYear);
  }
  getById(id: string) {
    return this.clientHttp.get(environment.API_URL + '/fiscal-years/' + id);
  }
  updateFiscalYear(id: string, fiscalYear: object) {
    return this.clientHttp.post(environment.API_URL + '/fiscal-years/' + id, fiscalYear);
  }
  updateFiscalYearStatus(id: string, status: string) {
    return this.clientHttp.post(environment.API_URL + '/fiscal-years/' + id + '/status/' + status, status);
  }
  getByStatus(status: string) {
    return this.clientHttp.get(environment.API_URL + '/fiscal-years/status' + status);
  }

}
