import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { TransferDataService } from './services/transfer-data/transfer-data.service';
import { UserService } from './services/user/user.service';
import { empty } from './utils/functions/functions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'e-imihigo';
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private transferService: TransferDataService
  ) {}
  ngOnInit() {
    this.setUser();
  }

  setUser() {
    const {user} = this.authService.loggedIn() as any;
    if (!user) {
      return false;
    }
    this.transferService.setAuthUser(user);
  }
}
