import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { empty } from 'src/app/utils/functions/functions';
import { userTypes } from 'src/app/utils/models/user.model';
import { TransferDataService } from '../../transfer-data/transfer-data.service';

@Injectable({
  providedIn: 'root'
})
export class ImihigoSetterGuard implements CanActivate {
  roles = userTypes;
  constructor(private transferService: TransferDataService, private router: Router) { }
  canActivate() {
    if (this.isSetter()) {
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { isAuthorised: false } });
      return false;
    }
  }

  isSetter() {
    let isUserSetter: boolean;
    const started = true;
    this.transferService.getAuthUser().subscribe((user: any) => {
      if (empty(user)) { return; }
      const role = user.userType;
      if (role === this.roles.SETTER) {
        isUserSetter = true;
      } else {
        isUserSetter = false;
      }
    });
    if (started) { return isUserSetter; }
  }
}
