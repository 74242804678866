export interface IUser {
  id: string;
  email: string;
  firstName: string;
  institutionId: string;
  lastName: string;
  nidNumber: string;
  password: string;
  phone: string;
  position: string;
  preferredLanguage: preferedLang;
  roleId: string;
  status: useStatus;
  signingOrder: number;
  title: string;
  userType: userTypes;
  username: string;
}

enum preferedLang {
  rw = 'rw',
  en = 'en',
}

export enum useStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  RESOLVED = 'RESOLVED',
  RESET = 'RESET',
}

export enum userTypes {
  ANONYMOUS = 'ANONYMOUS',
  SYSTEM = 'SYSTEM',
  ADMIN = 'ADMIN',
  CONTRACT_UPLOADER = 'CONTRACT_UPLOADER',
  SETTER = 'SETTER',
  VIEWER = 'VIEWER ',
  PRESIDENT = 'PRESIDENT',
}
