import { TransferDataService } from './../../services/transfer-data/transfer-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { checkPasswords } from 'src/app/utils/validators/confirmed-validator';
import { PASSWORD_PATTERN } from 'src/app/utils/constants/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit {
  updatePassword: FormGroup;
  alertUser: boolean;
  alertMessage: string;
  alertClass: string;
  userId: any;
  errorMessage: string;
  constructor(
    private userService: UserService,
    private router: Router,
    private transferDataService: TransferDataService,
    private _location: Location
  ) {
    this.transferDataService.getAuthUser().subscribe(currUser => {
      this.userId = currUser;
    });
    this.updatePassword = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(PASSWORD_PATTERN),
      ]),
      currentPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(PASSWORD_PATTERN),
      ]),
      c_password: new FormControl('', [
        Validators.required,
        Validators.pattern(PASSWORD_PATTERN),
      ]),
    });
    this.updatePassword.setValidators(checkPasswords);
  }
  ngOnInit() { }

  handleBack() {
    this._location.back();
  }

  updatePasswordFunc() {
    const submitValues = {
      currentPassword: this.updatePassword.get('currentPassword').value,
      password: this.updatePassword.get('password').value,
    };

    this.errorMessage = ' ';

    this.userService
      .updatePasswordService(this.userId.id, submitValues)
      .subscribe(
        (response: any) => {
          this.alertUser = true;
          this.alertClass = 'alert-success';
          this.alertMessage = 'Password updated successfully';
          // this.updateInstitution.reset();
          // this.router.navigate(['/admin/institutions']);
        },
        error => {
          this.alertUser = true;
          this.alertClass = 'alert-danger';
          this.alertMessage = error.error.message;
        }
      );
  }
}
