import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DIGIT_PATTERN, PASSWORD_PATTERN } from 'src/app/utils/constants/constants';
import { checkPasswords } from 'src/app/utils/validators/confirmed-validator';

let par = new URLSearchParams(window.location.search)

let userEmail = par.get("email");
let code = par.get("code");
  


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  

  forgotPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  alertUser: boolean;
  step: number;
  alertClass: string;
  alertMessage: string;
  email: string;
  code: string;
  constructor(private authService: AuthService, private router: Router) {

    this.resetPasswordForm = new FormGroup({
      email: new FormControl(userEmail, [Validators.required, Validators.email]),
      activationCode: new FormControl(code, [Validators.required, Validators.pattern(DIGIT_PATTERN)]),
      password: new FormControl('', [Validators.required, Validators.pattern(PASSWORD_PATTERN)]),
      c_password: new FormControl('', [Validators.required, Validators.pattern(PASSWORD_PATTERN)]),

    });
    this.resetPasswordForm.setValidators(checkPasswords);

  }

  ngOnInit() {
    this.email = userEmail;
    this.code= code;
  }


  onResetPassword() {
    this.authService.resetPassword(this.resetPasswordForm.value).subscribe((res: any) => {
      this.alertUser = true;
      this.alertClass = 'alert-success';
      this.alertMessage = res.message;
      this.reset();
      setTimeout(() => {
        this.router.navigate(['/login'])
    }, 4000);

    }, (error: HttpErrorResponse) => {
      this.alertUser = true;
      this.alertClass = 'alert-danger';
      this.alertMessage =
        error.status === 500 ? 'An error occured' : error.error.message;

    });
    // this.validateAllFormFields(this.forgotPasswordForm);
  }

  reset() {
    this.resetPasswordForm.reset({
        "activationCode": "",
        "password": "",
        "email": "",
        "c_password": ""
    })
}



}
