import { Component, OnInit } from '@angular/core';
declare let $:any;
@Component({
  selector: 'app-imihigo-setter',
  templateUrl: './imihigo-setter.component.html',
  styleUrls: ['./imihigo-setter.component.scss']
})
export class ImihigoSetterComponent implements OnInit {

  constructor() { }
  toggleSidebar(){
  
    $(()=>{
      let sidebar = $('#sidebar');
      if(sidebar.hasClass('active')){
        sidebar.removeClass('active')
        $('#triggerIcon').removeClass('fa-times');
        $('#triggerIcon').addClass('fa-bars');
      }
      else{
        sidebar.addClass('active');
        $('#triggerIcon').removeClass('fa-bars');
        $('#triggerIcon').addClass('fa-times');
      }
    })
  }
  ngOnInit() {
  }

}
