import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TransferDataService } from 'src/app/services/transfer-data/transfer-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { empty } from 'src/app/utils/functions/functions';
import { USERROLES } from 'src/app/utils/user-types';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  roles = USERROLES;
  activeRole: USERROLES;
  activeRoute: string;
  institutionType: string;
  CurrentUser: any;

  constructor(
    private transferService: TransferDataService,
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.router.events.subscribe(route => {
      if (route instanceof NavigationEnd) {
        this.activeRoute = route.url;
      }
    });
    //this.getUser();
    this.getCurrentUser();
  }
  onRoute(itemId) {
    $(() => {
      const navItem = $('.nav-item');
      if (navItem.hasClass('active-button')) {
        navItem.removeClass('active-button');
      }
      $(`#${itemId}`).addClass('active-button');
    });
  }

  ngOnInit() { }

  getUser() {
    this.transferService.getAuthUser().subscribe((user: any) => {
      if (empty(user)) {
        return;
      }

      this.activeRole = user.userType;

      this.CurrentUser = user;

      if (user.institution) {
        this.institutionType = user.institution.institutionType;
      }
    });
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((user: any) => {
      if (empty(user)) {
        return;
      }

      this.activeRole = user.userType;

      this.CurrentUser = user;

      if (user.institution) {
        this.institutionType = user.institution.institutionType;
      }
    });
  }
}
