import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) { }

  // Users
  getAll() {
    return this.httpClient.get(environment.API_URL + '/users/getUsers');
  }
  getCurrentUser() {
    return this.httpClient.get(environment.API_URL + '/auth/current');
  }
  getOne(id: string) {
    return this.httpClient.get(
      environment.API_URL + '/users/getUserById/' + id
    );
  }
  createUser(user: object) {
    return this.httpClient.post(
      environment.API_URL + '/users/registerNewUser',
      user
    );
  }
  updateUser(id, user: object) {
    return this.httpClient.post(
      environment.API_URL + '/users/adminModifyUser/' + id,
      user
    );
  }

  userupdateSelf(user: object, id: string) {
    return this.httpClient.post(
      `${environment.API_URL}/users/userSelfModify/${id}`,
      user
    );
  }

  updateUserPassword(user: object, id: string) {
    return this.httpClient.post(
      `${environment.API_URL}/users/changeCredentials/${id}`,
      user
    );
  }

  deleteUser(id: object) {
    return this.httpClient.delete(
      environment.API_URL + '/users/removeUser/' + id
    );
  }

  // Roles
  getAllRoles() {
    return this.httpClient.get(environment.API_URL + '/roles/getRoles');
  }

  // Institutions
  getAllInstitutions() {
    return this.httpClient.get(environment.API_URL + '/institutions/getAll');
  }
  updatePasswordService(userId: string, passwords: object) {
    return this.httpClient.post(environment.API_URL + '/users/changeCredentials/' + userId, passwords);
  }
  getUserSignature(filename: string) {
    return this.httpClient.get(
      environment.API_URL + '/users/user-signature/' + filename
    );
  }
  // updating signature
  attachSignature(id: string, file: FormData) {
    return this.httpClient.post(
      environment.API_URL + '/users/attachSignatureDocument/' + id,
      file
    );
  }

  getUserWithTypePresident() {
    return this.httpClient.get(`${environment.API_URL}/users/getUsersByUserType/PRESIDENT`);
  }
  //get user by id
  getUserByInsitution(institutionId) {
    return this.httpClient.get(`${environment.API_URL}/users/institutionId/${institutionId}`);
  }
}
