/**
 * DIGIT PATTERN
 */
export const DIGIT_PATTERN = /^(0|[0-9][0-9]*)$/;




/**
 * PASSWORD PATTERN
 */
export const PASSWORD_PATTERN = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[a-z]{1,}).{8,}$/;