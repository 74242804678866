import { Location } from '@angular/common';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TransferDataService } from 'src/app/services/transfer-data/transfer-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/user';
import { userTypes } from 'src/app/utils/models/user.model';

@Component({
  selector: 'app-update-account',
  templateUrl: './update-account.component.html',
  styleUrls: ['./update-account.component.scss'],
})
export class UpdateAccountComponent implements OnInit {
  isHovering: boolean = false;
  userUpdateAccount: FormGroup;
  userModal = new User();
  user: any;
  alertUser: boolean;
  alertClass: string;
  alertMessage: string;
  update_profile_form: FormGroup;
  avatar = '';
  selectedFile: File;
  currentFile: any;
  message: string;
  fileInformation: any;
  userId: string;
  pathToSignatureFile: string;
  user_id: string;
  userype: userTypes;

  constructor(
    private transferDataService: TransferDataService,
    private fb: FormBuilder,
    private userService: UserService,
    private _location: Location,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.user_id = params['id'];
    });

    this.userService.getOne(this.user_id).subscribe((resp: any) => {
      this.userype = resp.userType;
      this.userModal.id = resp.id;
      this.userModal.email = resp.email;
      this.userModal.fname = resp.firstName;
      this.userModal.institution = resp.institution;
      this.userModal.lname = resp.lastName;
      this.userModal.title = resp.title;
      this.userModal.phone = resp.phone;
      this.userModal.fileName = resp.fileName;
      this.pathToSignatureFile = resp.pathToSignatureFile;
      this.avatar = !resp.imageUrl
        ? `https://ui-avatars.com/api/?name=${resp.firstName}+${resp.lastName}&rounded=true&size=100&background=ff6d6d&color=fff`
        : this.user.imageUrl;
    });
  }
  ngOnInit(): void { }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(event) {
    this.isHovering = true;
    this.selectedFile = event[0];
    const fileData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    fileData.append('userSignature', this.selectedFile);

    this.userService.attachSignature(this.userModal.id, fileData).subscribe(
      (event: any) => {
        this.alertUser = true;
        this.alertClass = 'alert-success';
        this.alertMessage = 'Signature Uploaded Successfully';
        setTimeout(() => {
          this.transferDataService.setAuthUser(event);
          this.changeFileImage();
        }, 3000);
      },
      (err: HttpErrorResponse) => {
        this.alertUser = true;
        this.alertClass = 'alert-danger';
        this.alertMessage = 'Signature Upload Failed';
        // this.message = 'File not uploaded!';
      }
    );
  }

  onUpdateUser() {
    const userData = {
      firstName: this.userModal.fname,
      lastName: this.userModal.lname,
      phone: this.userModal.phone,
      email: this.userModal.email,
      title: this.userModal.title,
    };

    this.userService
      .userupdateSelf(userData, this.user_id)
      .subscribe(response => {
        this.alertUser = true;
        this.alertClass = 'alert-success';
        this.alertMessage = 'User Updated Successfully';
        this.transferDataService.setAuthUser(response);
        // this._location.back();
      }, (err: HttpErrorResponse) => {
        this.alertUser = true;
        this.alertClass = 'alert-danger';
        this.alertMessage = 'User Update Failed';
      });
  }

  handleBack() {
    this._location.back();
  }

  changeFileImage() {
    this.userService.getOne(this.user_id).subscribe((resp: any) => {
      this.pathToSignatureFile = resp.pathToSignatureFile;
      this.transferDataService.setAuthUser(resp);
    });
  }
}
