import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { empty } from 'src/app/utils/functions/functions';
import { userTypes } from 'src/app/utils/models/user.model';
import { TransferDataService } from '../../transfer-data/transfer-data.service';

@Injectable({
  providedIn: 'root'
})
export class PresidentGuard implements CanActivate {
  roles = userTypes;
  constructor(private router: Router, private transferService: TransferDataService) { }

  canActivate() {
    if (this.isPresident()) {
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { isAuthorised: false } });
      return false;
    }
  }

  isPresident() {
    let isUserPresident: boolean;
    const started = true;
    this.transferService.getAuthUser().subscribe((user: any) => {
      if (empty(user)) { return; }
      const role = user.userType;
      if (role === this.roles.PRESIDENT) {
        isUserPresident = true;
      } else {
        isUserPresident = false;
      }
    });
    if (started) { return isUserPresident; }
  }


}
