import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AdministratorComponent } from './layouts/administrator/administrator.component';
import { ImihigoSetterComponent } from './layouts/imihigo-setter/imihigo-setter.component';
import { ImihigoUploaderComponent } from './layouts/imihigo-uploader/imihigo-uploader.component';
import { ImihigoViewerComponent } from './layouts/imihigo-viewer/imihigo-viewer.component';
import { PresidentComponent } from './layouts/president/president.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { SecurityComponent } from './pages/security/security.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { UpdateAccountComponent } from './pages/update-account/update-account.component';
import { UploaderCreateAccountComponent } from './pages/uploader-create-account/uploader-create-account.component';
import { AuthGuard } from './services/guards/auth/auth.guard';
import { AdminGuard } from './services/guards/admin/admin.guard';
import { PresidentGuard } from './services/guards/president/president.guard';
import { ImihigoViewerGuard } from './services/guards/imihigo-viewer/imihigo-viewer.guard';
import { ImihigoUploaderGuard } from './services/guards/imihigo-uploader/imihigo-uploader.guard';
import { ImihigoSetterGuard } from './services/guards/imihigo-setter/imihigo-setter.guard';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { ViewPrintImihigoDocumentComponent } from './pages/view-print-imihigo-document/view-print-imihigo-document.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'admin',
    component: AdministratorComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layouts/administrator/administrator.module').then(
            module => module.AdministratorModule
          ),
      },
    ],
    canActivate: [AdminGuard, AuthGuard],
  },
  {
    path: 'president',
    component: PresidentComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layouts/president/president.module').then(
            module => module.PresidentModule
          ),
      },
    ],
    canActivate: [PresidentGuard, AuthGuard],
  },
  {
    path: 'imihigo-viewer',
    component: ImihigoViewerComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layouts/imihigo-viewer/imihigo-viewer.module').then(
            module => module.ImihigoViewerModule
          ),
      },
    ],
    canActivate: [ImihigoViewerGuard, AuthGuard],
  },
  {
    path: 'imihigo-uploader',
    component: ImihigoUploaderComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layouts/imihigo-uploader/imihigo-uploader.module').then(
            module => module.ImihigoUploaderModule
          ),
      },
    ],
    canActivate: [ImihigoUploaderGuard, AuthGuard],
  },
  {
    path: 'imihigo-setter',
    component: ImihigoSetterComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layouts/imihigo-setter/imihigo-setter.module').then(
            module => module.ImihigoSetterModule
          ),
      },
    ],
    canActivate: [ImihigoSetterGuard, AuthGuard],
  },
  {
    path: 'uploader-create-account',
    component: UploaderCreateAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile-settings/:id',
    component: UpdateAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'update-password',
    component: UpdatePasswordComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'security',
    component: SecurityComponent,
    canActivate: [AuthGuard],
  },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule { }
